import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import LinkButton from '../../UI/LinkButton';
import styles from './HeaderLinks.module.css';

const HeaderLinks = () => {
  return (
    <ul className={styles.list}>
      <li><LinkButton path={'/#work'} name={'Experience'} /><span className={styles.vl}></span></li>
      <li><LinkButton path={'/#abilities'} name={'Abilities'} /><span className={styles.vl}></span></li>
      <li><LinkButton path={'/#abt'} name={'About'} /></li>
      <li><a  target="_blank" rel="noreferrer" href={'https://www.linkedin.com/in/husam-zeidan-9015a4193/'} className={`${styles.header_link} ${styles.logo_button}`}><FaLinkedin /></a></li>
    </ul>
  );
};

export default HeaderLinks; 