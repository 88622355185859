// import { useState } from "react";
import styles from "./Landing.module.css";
import { NavHashLink as Link } from "react-router-hash-link";

const Landing = () => {

  // let windowHeight = window.innerHeight;
  // const [landingScreen, setLandingScreen] = useState(windowHeight);

  // const resetLandingScreen = () => {
  //   if (window.innerHeight !== windowHeight) {
  //     windowHeight = window.innerHeight;
  //     setLandingScreen(windowHeight);
  //   }
  // }

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

  return (
    <main className={styles.landing}>
      <h1 className={styles.greeting}>Hello, I'm Husam,</h1>
      <p className={styles.quote}>
        I am a recent graduate from the University of Michigan and am pursuing a
        career in Fullstack Development.
      </p>
      <Link smooth to={"/#work"} scroll={scrollWithOffset} className={styles.down}>
       &#10147;
      </Link>
    </main>
  );
};

export default Landing;
