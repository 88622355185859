import React, { useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { RiZoomInFill, RiZoomOutFill } from 'react-icons/ri';
import { Modal } from "react-bootstrap";
import styles from "./Projects.module.css";

const Projects = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container>
      <Row className={styles.layer}>
        <Col md={6} sm={12}>
          <div>
            <img
              className={`img-fluid ${styles.image}`}
              src={props.src}
              alt={props.alt}
            />
            <button
              className={styles.plus}
              variant="primary"
              onClick={handleShow}
            >
              <RiZoomInFill />
            </button>
            <Modal
              show={show}
              onHide={handleClose}
              id={styles.content}
              size="lg"
              centered
            >
              <Modal.Body id={styles.modal}>
                <img src={props.src} alt={props.alt}></img>
                <button
                  id={styles.close_btn}
                  variant="secondary"
                  onClick={handleClose}
                >
                  <RiZoomOutFill />
                </button>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
        <Col>
          <div>
            <h2 className={styles.title}>{props.title}</h2>
            <div className={styles.description}>{props.description}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
