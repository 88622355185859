import React, { useState } from "react";
import styles from "./Header.module.css";
import HeaderLinks from "./HeaderLinks";
import { NavHashLink as Link } from "react-router-hash-link";

const Header = () => {
  const [navbar, setNavBar] = useState(false);

  const navbarHandler = () => {
    const pageHeight = window.innerHeight - 80;
    if (window.scrollY >= pageHeight) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", navbarHandler);

  return (
      <header
        className={
          navbar ? `${styles.header} ${styles.active}` : `${styles.header}`
        }
      >
        <Link to={"#top"} className={styles.name}>
          Husam Zeidan
        </Link>
        <HeaderLinks />
      </header>
  );
};

export default Header;
