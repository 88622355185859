import { Accordion } from "react-bootstrap";
import { Container, Row, Col } from "react-grid-system";
import styles from "./Abilities.module.css";

const Abilities = (props) => {
  return (
    <Container className={styles.layer}>
      <Row>
        <Col md={6} xs={12}>
          <h3 className={styles.header}>Frontend</h3>
          <Accordion id={styles['accord-header']}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Languages</Accordion.Header>
              <Accordion.Body>
              <ul>
                  <li>HTML</li>
                  <li>CSS/Sass</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Frameworks/Libraries</Accordion.Header>
              <Accordion.Body>
              <ul>
                  <li>React</li>
                  <li>Bootstrap</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Practices</Accordion.Header>
              <Accordion.Body>
              <ul>
                  <li>Single Page Applications</li>
                  <li>Responsive Web Design</li>
                  <li>Automated Testing</li>
                  <li>Agile Methodologies</li>
                  <li>Test & Debug</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={6} xs={12}>
          <h3 id={styles.col_margin} className={styles.header}>Backend</h3>
          <Accordion id={styles['accord-header']}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Languages</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>JavaScript</li>
                  <li>Python</li>
                  <li>Java</li>
                  <li>C++</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Frameworks/Databases</Accordion.Header>
              <Accordion.Body>
              <ul>
                  <li>Node.js</li>
                  <li>Express</li>
                  <li>Flask</li>
                  <li>MongoDB</li>
                  <li>MySQL</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Practices</Accordion.Header>
              <Accordion.Body>
              <ul>
                  <li>Data Structures & Algorithms</li>
                  <li>Object Oriented Programming</li>
                  <li>Test & Debug</li>
                  <li>SCRUM</li>
                  <li>Git/Github</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Abilities;