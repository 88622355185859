import styles from "./LinkButton.module.css";
import { NavHashLink as Link } from "react-router-hash-link";

const LinkButton = (props) => {

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
  return (
    <Link
      smooth
      to={props.path}
      className={styles["link-button"]}
      onClick={(e) => e.currentTarget.blur()}
      scroll={scrollWithOffset}
    >
      {props.name}
    </Link>
  );
};

export default LinkButton;
