import styles from "./About.module.css";
import { Family } from "../../images/index";
import { Container, Row, Col, Hidden } from "react-grid-system";
const About = () => {
  return (
    <Container>
      <Row id={styles.about}>
        <Col md={4}>
          <Hidden sm xs>
            <img className={styles.image} src={Family} alt="Family" />
          </Hidden>
        </Col>
        <Col md={8} xs={12}>
          <p className={styles.summary}>
            There are a few things I enjoy outside of sitting on my computer for
            hours and programming. I love to spend time with my friends and
            family. I grew up in a rather large family considering I had 11
            siblings living at home at the time. Now most are married and out of
            the house but we always plan to spend one day out of the week
            together whether its at a park, a restaurant, or enjoying each
            others company at home. The rest of my free time is spent focusing
            on my hobbies. I really enjoy watching and learning about combat
            sports for the most part. The unique and creative styles each
            individual has in a sport such as MMA really captivates me. My
            brothers and I spend every Saturday evening watching UFC or boxing
            events. The excitement we get out of watching it is unlike any other
            sport. To learn more about me on a more personal level, please press
            the contact button bellow. Looking forward to speaking to you soon!
          </p>
        </Col>
        <span id={styles.contact}>
          <a href={"mailto:huzeidan@umich.edu"}> </a>
        </span>
      </Row>
    </Container>
  );
};

export default About;
