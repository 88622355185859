import React, { Fragment } from "react";
import Header from "./Components/Header/Header";
import Landing from "./Components/Landing/Landing";
import SubHeader from "./UI/SubHeader";
import Abilities from "./Components/Abilities/Abilities";
import "./App.css";
import Projects from "./Components/Projects/Projects";
import About from "./Components/About/About";
import { FoodApp, Intern, ExpressEmail } from "./images/index";
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const projectDescription = [
  <p>
    The Express Email web app utilized the full stack of web development.
    Focusing on the MERN stack (MongoDB, Express.js, React.js and Node.js) I
    created a dynamic environment that allows user login and online payments.
    This web app allows a user to create a list of surveys to automatically send
    out to customers, organizations, or groups. The user signs in using Google
    Oauth and can purchase credits to create the surveys. The credits can be
    purchased using Stripe and each credit is good for one survey no matter the
    amount of emails being sent out. The user provides the emails and
    information on the survey and will receive the client’s responses to the
    survey.
  </p>,
  <p>
    This Quick Grill app is a simple food order app that simulates customers
    shopping online. It uses React, JavaScript, and CSS styling to allow a user
    to pick from a list of food items and add it to their cart. Once the items
    are added to the cart, the users can click on the cart button and see their
    list of items chosen. From there, the user can add or delete items and if
    they are finished can checkout and pay for the available items in their
    cart. This app really focuses on using React Hooks such as useState,
    useEffect, useRef, useContext, etc.
  </p>,
  <p>
    During my time working with JNS Maintenance Services, I really focused most
    of my time using frontend languages and frameworks to help design their
    company website. I used HTML, CSS, and JavaScript in a Wordpress environment
    to help put out content and style the pages. I also focused on optimizing
    and cleaning the bugs in the code by adding updated functionalities and
    comments. Once the site was complete, it helped increase customer traffic by
    over 10% in just a matter of months. I worked with a small team that really
    helped me learn every step of the way.
  </p>,
];

function App() {
  return (
    <Fragment>
      <Header />
      <Landing />
      <SubHeader id={"work"} header={"Projects & Experience"} />
      <Projects
        src={ExpressEmail}
        alt={"Google Oauth"}
        title={"Express Email App"}
        description={projectDescription[0]}
      />
      <Projects
        src={FoodApp}
        alt={"Home Page"}
        title={"Food Order App"}
        description={projectDescription[1]}
      />
      <Projects
        src={Intern}
        alt={"Internship site"}
        title={"Web & Creative Intern"}
        description={projectDescription[2]}
      />
      <SubHeader id={"abilities"} header={"Computing Abilities"} />
      <Abilities />
      <SubHeader id={"abt"} header={"About"} />
      <About />
    </Fragment>
  );
}

export default App;
